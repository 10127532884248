
import { Component } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../../../support/mixins'

import { ValidationRules } from '../../../../shared/molecules/Form'

import {
  CUSTOMER_DETAILS_STEP_COMPONENT_KEY,
  CUSTOMER_DETAILS_STEP_COMPONENT_KEY_CONFIG_MAP, CustomerDetailsStepConfig
} from '../CustomerDetailsStep.config'

/**
 * Helper class that groups all CustomerDetailsStep component configuration
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CustomerDetailsCheckoutStepConfig>({
  name: 'CustomerDetailsCheckoutStepConfig',
  created () {
    this.config = this.getComponentConfig(CUSTOMER_DETAILS_STEP_COMPONENT_KEY, { ...CUSTOMER_DETAILS_STEP_COMPONENT_KEY_CONFIG_MAP })
  }
})
export class CustomerDetailsCheckoutStepConfig extends StructureConfigurable {
  protected config!: CustomerDetailsStepConfig

  public get guestDetailsFieldsetType (): string {
    return this.getConfigProperty('guestDetailsFieldsetType')
  }

  public get displayVatIdVerification (): boolean {
    return this.getConfigProperty('displayVatIdVerification')
  }

  public get withCompanyCheckboxFields (): boolean {
    return this.getConfigProperty('withCompanyCheckboxFields')
  }

  public get submitLabel (): string {
    return this.getConfigProperty('submitLabel')
  }

  public get hasRegionField (): boolean {
    return this.getConfigProperty('hasRegionField')
  }

  public get shouldDisplayCompanyNameField (): boolean {
    return this.getConfigProperty('shouldDisplayCompanyNameField')
  }

  public get withRegistrationFields (): boolean {
    return this.getConfigProperty('withRegistrationFields')
  }

  public get withSocials (): boolean {
    return this.getConfigProperty<boolean>('withSocials')
  }

  public get validatorsConfig (): ValidationRules | Record<string, ValidationRules> {
    return this.getConfigProperty<ValidationRules | Record<string, ValidationRules>>('validators')
  }
}
