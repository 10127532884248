























import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus
} from '@movecloser/front-core'
import { Component, Inject as VueInject, Mixins } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import { AuthControlServiceType, IAuthControl, SignupFormPayload } from '../../../auth/contracts'
import { CartAnalyticsMixin } from '../../../shared/mixins/cart-analytics.mixin'
import { CartBillingAddress, CartShippingAddress } from '../../../../contexts'
import {
  DictionaryServiceType,
  IDictionaryService,
  ShipmentRegion
} from '../../../shared/services/dictionary'
import { IProfileService, ProfileServiceType } from '../../../profile/contracts'
import { UserModel } from '../../../auth/shared'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY, logger } from '../../../../support'
import { validatePayload } from '../../../shared/support/validate-payload'
import { ValidationRules } from '../../../shared/molecules/Form'

import {
  AddressFormData,
  AddressFormFieldset,
  AddressFormFieldsetProps
} from '../../molecules/AddressFormFieldset'
import { CART_ID_KEY, CartServiceType, ICartService } from '../../services/cart'
import {
  GuestDetailsFormData,
  GuestDetailsFormFieldset
} from '../../molecules/GuestDetailsFormFieldset'
import { NoteForm } from '../../molecules/NoteForm'
import { translateCheckoutPayloadToValidationObject } from '../../helpers/checkout.helpers'
import { CartMutationTypes } from '../../contracts'

import { AbstractStep } from '../AbstractStep'
import { customerDetailsValidatorsMap } from './CustomerDetailsStep.helpers'
import { CustomerDetailsCheckoutStepConfig } from './Base/CustomerDetailsCheckoutStepConfig'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<CustomerDetailsStep>({
  name: 'CustomerDetailsStep',
  components: {
    AddressFormFieldset,
    GuestDetailsFormFieldset,
    NoteForm
  },
  mounted (): void {
    if (this.cart) {
      this.eventBus.emit('app:checkout.begin', this.getBaseCheckoutPayload(this.cart))
    }
  }
})
export class CustomerDetailsStep extends Mixins(AbstractStep,
  CartAnalyticsMixin, CustomerDetailsCheckoutStepConfig) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(CartServiceType)
  public readonly cartService!: ICartService

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(DictionaryServiceType)
  protected readonly dictionaryService!: IDictionaryService

  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: defaultProvider(false) })
  public readonly isMobileProvider!: () => boolean

  public errors: Record<string, string[]> | null = null
  public hasInvoice: boolean = false

  public get addressFormData (): AddressFormData {
    return this.payload.address
  }

  public set addressFormData (data: AddressFormData) {
    this.onChange('address', data)
  }

  public get countries (): AddressFormFieldsetProps['countries'] {
    return this.dictionaryService.countries.map(country => ({
      label: country.fullNameLocale,
      value: country.id
    }))
  }

  public get isDisabled (): boolean {
    return false
  }

  public get note (): string {
    return this.payload.note
  }

  public set note (note: string) {
    this.onChange('note', note)
  }

  public get pages (): Record<string, string> {
    return this.siteService?.getActiveSiteUrls()
  }

  public clearErrors (): void {
    this.errors = null
  }

  public async initCartOnRegister (guestCartId: string): Promise<string> {
    this.$store.commit(CartMutationTypes.SetRegisteringInCheckout, true)
    let cartId = guestCartId
    try {
      const customerCart = await this.cartService.loadCustomerCart()
      const combinedCart = await this.cartService.mergerCarts(guestCartId, customerCart.id)

      this.$store.commit(CartMutationTypes.SetCartId, combinedCart.id)
      this.cartService.setCartId(combinedCart.id)
      cartId = combinedCart.id
    } catch (e) {
      logger(e, 'warn')
      // this.showToast((e as Error).message, 'danger')
    } finally {
      this.$store.commit(CartMutationTypes.SetLoading, false)
      this.$store.commit(CartMutationTypes.SetRegisteringInCheckout, false)
    }

    return cartId
  }

  public static isValidStep (
    payload: AnyObject,
    translate: (key: string) => TranslateResult,
    locale: string,
    setErrors?: (errors: Record<string, string[]>) => void,
    validators?: ValidationRules
  ): boolean {
    const result = validatePayload(
      payload,
      validators ?? customerDetailsValidatorsMap,
      translate,
      locale
    )

    if (result.errors && setErrors) {
      setErrors(result.errors)
    }

    return !result.errors || Object.keys(result.errors).length === 0
  }

  public onSignUpRequest (request: boolean): void {
    this.onChange('isSignupRequested', request)
  }

  public setErrors (errors: Record<string, string[]>): void {
    this.errors = errors
  }

  public setHasInvoice (value: boolean): void {
    this.hasInvoice = value
    this.onChange('hasInvoice', value)
  }

  public setSameBillingAddress (value: boolean): void {
    this.onChange('sameBillingAddress', value)
  }

  public async submit (): Promise<void> {
    if (!this.cart) {
      return
    }

    if (
      !CustomerDetailsStep.isValidStep(
        translateCheckoutPayloadToValidationObject(this.payload),
        this.$t.bind(this),
        this.$i18n.locale,
        this.setErrors
      )
    ) {
      return
    }

    this.$emit('saving', true)
    try {
      if (this.payload.isSignupRequested) {
        const tokenModel = await this.authControl.signup({
          ...this.payload.user,
          acceptPrivacy: true,
          subscribeToNews: false
        } as SignupFormPayload)

        this.authService.setToken(tokenModel.toAuthToken())

        const cartId = await this.initCartOnRegister(this.cart.id)

        if (!this.authService.token) {
          return
        }

        await this.profileService.storeDeliveryAddress({
          firstName: this.payload.user.firstName ?? '',
          lastName: this.payload.user.lastName ?? '',
          postalCode: this.payload.address.postalCode,
          vatId: this.payload.address.invoice?.nip,
          city: this.payload.address.city,
          company: this.payload.address.company,
          street: this.payload.address.address,
          defaultBilling: true,
          defaultShipping: false,
          countryCode: this.payload.address.country,
          phoneNumber: this.payload.address.phone
        })

        await this.profileService.getUserDetails(this.authService.token.accessToken).then((user) => {
          this.authService.setUser(user)
          this.$store.commit('profile/SET_USER', user)
        })

        this.$nextTick(this.continueSubmit.bind(this, cartId))
      } else {
        if (!this.payload.isUserLoggedIn) {
          await this.checkoutService.setGuestEmail(this.cart.id, this.payload.user.email)
        }

        await this.continueSubmit(this.cart.id)
      }
    } catch (e) {
      logger(e, 'warn')
      this.$emit('error', (e as Error).message)
    } finally {
      this.$emit('saving', false)
    }
  }

  public updateGuestFormData (data: GuestDetailsFormData) {
    this.onChange('user', data)
  }

  protected async continueSubmit (cartId: string): Promise<void> {
    await this.checkoutService.setShippingAddress(cartId, this.cartShippingAddress(this.hasRegionField), false, this.hasRegionField)

    const cart = await this.checkoutService.setBillingAddress(
      cartId,
      this.cartBillingAddress
    )

    this.$store.commit(CartMutationTypes.SetCart, cart)

    // FIXME
    this.$emit('saving', false)
    this.nextStep()
  }

  private get cartBillingAddress (): CartBillingAddress {
    let address

    if (this.payload.sameBillingAddress) {
      address = {
        city: this.payload.address.city,
        postcode: this.payload.address.postalCode,
        street: [this.payload.address.address]
      }
    } else {
      address = {
        city: this.payload.address.billingAddress.city || this.payload.address.city,
        postcode: this.payload.address.billingAddress.postalCode || this.payload.address.postalCode,
        street: [this.payload.address.billingAddress.address || this.payload.address.address]
      }
    }

    return {
      ...address,
      company: this.payload.address.invoice?.company || this.payload.address.company,
      countryCode: this.payload.address.country,
      firstname: this.payload.user.firstName ?? '',
      lastname: this.payload.user.lastName ?? '',
      sameAsShipping: this.payload.hasInvoice ? false : this.payload.sameBillingAddress,
      telephone: this.payload.address.phone,
      vatId: this.payload.address.invoice?.nip
    }
  }

  private cartShippingAddress (hasRegionField: boolean = false): CartShippingAddress {
    let data: CartShippingAddress = {
      city: this.payload.address.city,
      company: this.payload.address.company,
      countryCode: this.payload.address.country,
      customerNotes: this.note,
      firstname: this.payload.user.firstName ?? '',
      lastname: this.payload.user.lastName ?? '',
      postcode: this.payload.address.postalCode,
      street: [this.payload.address.address],
      telephone: this.payload.address.phone,
      vatId: this.payload.address.invoice?.nip
    }

    if (hasRegionField && this.payload.address.region) {
      data = {
        ...data,
        region: (this.payload.address.region as ShipmentRegion).id.toString()
      }
    }

    return data
  }
}

export default CustomerDetailsStep
