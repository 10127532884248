














































































































































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator'
import set from 'lodash/set'

import { SelectItem } from '../../../../dsl/atoms/Select'

import Consents from '../../../shared/molecules/Consents/Consents.vue'
import { ShipmentRegion } from '../../../shared/services/dictionary'
import { ShipmentRegionsMixin } from '../../../shared/mixins/shipment-regions.mixin'

import { CheckoutPayload } from '../../contracts'

import { GuestDetailsFormFieldset } from './GuestDetailsFormFieldset.vue'
import { Country } from '../../../../contexts'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<GuestDetailsFormFieldsetCompanySimplified>({
  name: 'GuestDetailsFormFieldsetCompanySimplified',
  components: { Consents },
  created () {
    this.retrieveShipmentRegions(true)
    this.composeFormContexts()
  },
  mounted () {
    if (this.hasShipmentRegions && Object.prototype.hasOwnProperty.call(this._formData.address, 'region')) {
      this.regionCode = (this._formData.address.region as ShipmentRegion).code
    }
  }
})
export class GuestDetailsFormFieldsetCompanySimplified extends Mixins(GuestDetailsFormFieldset, ShipmentRegionsMixin) {
  /**
   * @override
   */
  @PropSync('formData', { type: Object, required: true })
  protected _formData!: CheckoutPayload

  @Prop({ type: Array, required: true })
  public readonly countries!: Array<Country>

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withRegistrationFields!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public readonly withCompanyCheckboxFields!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly displayVatIdVerification!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly isAlwaysGuest!: boolean

  public onlyCompany: boolean | null = null
  public isCompany: boolean = false
  public regionCode?: string = ''

  public get hasShipmentRegions (): boolean {
    return Array.isArray(this.shipmentRegions) && this.shipmentRegions.length > 0
  }

  public get regionOptions (): Array<SelectItem> | undefined {
    if (!this.hasShipmentRegions) {
      return
    }

    return this.shipmentRegions!.map((region) => {
      return {
        label: region.name,
        value: region.code
      }
    })
  }

  public onIsCompanyUpdate (value: boolean): void {
    this.isCompany = value

    this.$emit('hasInvoice', value)
  }

  public onPasswordChange (key: string, value: string): void {
    if (value.length > 0) {
      this.$emit('signupChanged', true)
    }

    this.updateModel(`user.${key}`, value)

    if (value && value.length > 0) {
      this.onSignupChange(true)
    } else {
      this.onSignupChange(false)
    }
  }

  public onRegionUpdate (value: string): void {
    this.regionCode = value

    if (this.shipmentRegions) {
      this.updateModel('address.region', this.shipmentRegions.find((region) => region.code === this.regionCode))
    }
  }

  /**
   * Updates model (works with nested objects reactivity (reference))
   * @param key - `first.second.third.x`
   * @param value - `value for key`
   */
  public updateModel (key: string, value: unknown): void {
    const newFormData = { ...this._formData }

    set(newFormData, key, value)

    this._formData = newFormData

    if (key === 'address.country') {
      this.retrieveShipmentRegionsByLocaleId(value as string)
    }

    this.$emit('update:errors')
  }

  protected composeFormContexts () {
    if (!this.siteService) {
      return
    }

    const settings = this.siteService.getProperty('settings') as AnyObject | null

    this.onlyCompany = !!settings?.onlyCompany

    if (this.onlyCompany) {
      this.onIsCompanyUpdate(true)
    }
  }
}

export default GuestDetailsFormFieldsetCompanySimplified
