import { VueConstructor } from 'vue'

import { ValidationRules } from '../../../shared/molecules/Form'

import GuestDetailsFormFieldsetCompanySimplified
  from '../../molecules/GuestDetailsFormFieldset/GuestDetailsFormFieldsetCompanySimplified.vue'
import { GuestDetailsFormFieldsetTwo } from '../../molecules/GuestDetailsFormFieldset'

import { customerDetailsValidatorsMap } from './CustomerDetailsStep.helpers'

export interface CustomerDetailsStepConfig {
  buttonGoNextTheme: string
  guestDetailsFieldsetType: string
  hasRegionField: boolean
  shouldDisplayCompanyNameField: boolean
  submitLabel: string
  withCompanyCheckboxFields: boolean
  withRegistrationFields: boolean
  validators: ValidationRules | Record<string, ValidationRules>
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum AddressMode {
  Edit = 'edit',
  New = 'new',
  Preview = 'preview'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum GuestDetailsFieldsetType {
  CompanySimplified = 'companySimplified',
  Two = 'two'
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const guestDetailsFieldsetComponentsRegistry: Record<string, VueConstructor> = {
  [GuestDetailsFieldsetType.CompanySimplified]: GuestDetailsFormFieldsetCompanySimplified,
  [GuestDetailsFieldsetType.Two]: GuestDetailsFormFieldsetTwo
}

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export enum CustomerDetailsStepContext {
  Login = 'login',
  Register = 'register'
}

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const CUSTOMER_DETAILS_STEP_COMPONENT_KEY = 'CustomerDetailsStep'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const CUSTOMER_DETAILS_STEP_COMPONENT_KEY_CONFIG_MAP: CustomerDetailsStepConfig = {
  buttonGoNextTheme: 'secondary',
  guestDetailsFieldsetType: 'two',
  hasRegionField: false,
  shouldDisplayCompanyNameField: true,
  submitLabel: 'goNext',
  withCompanyCheckboxFields: true,
  withRegistrationFields: false,
  // todo: mena: {... postalCode: 'filled|string|min:5|max:7', }
  validators: customerDetailsValidatorsMap
}
